import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import { BreathingExerciseSelector } from "./components/BreathingExerciseSelector/BreathingExerciseSelector";
import { BreathingExercise } from "shared/request/myHealthyAdvantageApi";
import { useState } from "react";
import { BreathingExerciseRunner } from "./components/BreathingExerciseRunner/BreathingExerciseRunner";
import { BreathingExerciseResults } from "./components/BreathingExerciseResults/BreathingExerciseResults";
import PageTitle from "UIPalette/PageTitle/PageTitle";

export const BreathingExercises = () => {
  const { t } = useTranslation();
  const [selectedExercise, setSelectedExercise] = useState<BreathingExercise | undefined>(undefined);
  const [exerciseDuration, setExerciseDuration] = useState(3);
  const [completed, setCompleted] = useState(false);

  const handleStartExercise = (exercise: BreathingExercise, duration: number) => {
    setSelectedExercise(exercise);
    setExerciseDuration(duration);
  };

  const handleExerciseCancelled = () => {
    setSelectedExercise(undefined);
  };

  const handleExerciseCompleted = () => {
    setCompleted(true);
  };

  const handleDone = () => {
    setCompleted(false);
    setSelectedExercise(undefined);
  };

  return (
    <>
      <PageTitle title={t("pageTitle.resources.breathingExercises")} />
      <CurvedTopBanner>
        <PageHeader title={t("breathingExercises.heading")} backRoute="/resources" />
      </CurvedTopBanner>
      <p className="pb-3">{t("breathingExercises.headingParagraph")}</p>

      {!selectedExercise && <BreathingExerciseSelector onStartExercise={handleStartExercise} />}
      {selectedExercise && !completed && (
        <BreathingExerciseRunner
          exercise={selectedExercise}
          duration={exerciseDuration}
          onCancel={handleExerciseCancelled}
          onComplete={handleExerciseCompleted}
        />
      )}
      {completed && <BreathingExerciseResults exercise={selectedExercise!} duration={exerciseDuration} onDone={handleDone} />}
    </>
  );
};
