import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { TrackerChart } from "../../components/Charts/TrackerChart";
import { ChartDayOffsets } from "../../components/Charts/helpers";
import { useEffect, useState } from "react";
import { UserTrackerValue, useGetUserTrackerValuesFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { t } from "i18next";
import { convertMinutesToHours } from "core/helpers/time";

export const SleepTrackerChart = () => {
  const today = new Date();
  const [dateFrom, setDateFrom] = useState<Date>(today.addDays(ChartDayOffsets.week));
  const { getCurrentValue } = useTrackerContext();
  const {
    data: sleepTrackerData,
    mutate,
    isLoading,
    error,
  } = useGetUserTrackerValuesFunctionRequest({
    from: dateFrom.toDateOnlyString(),
    to: today.toDateOnlyString(),
    trackerType: TrackerType[TrackerType.Sleep],
  });

  useEffect(() => {
    mutate();
  }, [getCurrentValue, mutate]);

  const mappedData: UserTrackerValue[] =
    sleepTrackerData?.values.map((data) => ({
      date: data.date,
      value: convertMinutesToHours(data.value),
    })) ?? [];

  return (
    <TrackerChart
      heading={t("healthHub.trackers.sleep.chart.heading")}
      dateRange={{ startDate: dateFrom, endDate: today }}
      yAxisStepSize={1}
      yAxisLabel={t("healthHub.trackers.sleep.chart.yLabel")}
      loading={isLoading}
      showError={error !== undefined}
      data={mappedData}
      reload={() => mutate()}
      onChartViewOffsetChange={(offset) => setDateFrom(today.addDays(offset))}
      initialChartViewOffset={ChartDayOffsets.week}
    />
  );
};
