import { t } from "i18next";
import { useState } from "react";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { GeneralActivityInputModal } from "./GeneralActivityInputModal";

export const GeneralActivityInput = () => {
  const [showAddDataModal, setShowAddDataModal] = useState(false);
  return (
    <>
      <StylableButton
        text={t("healthHub.trackers.activity.addData.addButtonLabel")}
        color="primary"
        fullWidth={true}
        onClick={() => setShowAddDataModal(true)}
      />
      <Hideable hidden={!showAddDataModal}>
        <GeneralActivityInputModal onClose={() => setShowAddDataModal(false)} />
      </Hideable>
    </>
  );
};
