import { useNavigate } from "react-router-dom";

import { ReactComponent as Meditation } from "shared/assets/Icons/meditation.svg";
import { ReactComponent as Fitness } from "shared/assets/Icons/fitness.svg";
import { ReactComponent as Recipes } from "shared/assets/Icons/recipes.svg";
import { t } from "i18next";
import { WellKnownLearningMaterialSubCategories } from "UIPalette/LearningMaterials/wellKnownLearningMaterials";
import { ResourceTile } from "../ResourceTile";
import { trackFilteredInterests } from "core/monitoring/Events";

export const WellbeingResourceTiles = () => {
  const navigate = useNavigate();

  const handleClickMeditation = () => {
    trackFilteredInterests({ filterValue: "Meditation" });
    navigate(`/resources/audio-and-video/#si=${WellKnownLearningMaterialSubCategories.sleep}`, { state: { hideInterests: true } });
  };

  const handleClickRecipes = () => {
    trackFilteredInterests({ filterValue: "Recipes" });
    navigate(`/resources/articles/#si=${WellKnownLearningMaterialSubCategories.recipes}`, { state: { hideInterests: true } });
  };

  const handleClickFitness = () => {
    trackFilteredInterests({ filterValue: "Fitness" });
    navigate(`/resources/audio-and-video/#si=${WellKnownLearningMaterialSubCategories.improvingYourPhysicalHealth}`, {
      state: { hideInterests: true },
    });
  };

  return (
    <div className="flex flex-row justify-around text-lg lg:text-xl w-full h-fit gap-2 md:gap-4">
      <ResourceTile
        title={t("resources.meditationLinkName")}
        ariaLabel={t("resources.meditationNavigationLabel")}
        ResourceIcon={Meditation}
        onClick={handleClickMeditation}
      />
      <ResourceTile
        title={t("resources.recipesLinkName")}
        ariaLabel={t("resources.recipesnNavigationLabel")}
        ResourceIcon={Recipes}
        onClick={handleClickRecipes}
      />
      <ResourceTile
        title={t("resources.fitnessLinkName")}
        ariaLabel={t("resources.fitnessNavigationLabel")}
        ResourceIcon={Fitness}
        onClick={handleClickFitness}
      />
    </div>
  );
};
