export const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

export const isHealthAssuredLocale = (locale: string): boolean => locale === "en";

export const ukLocale = "dac1f6b0-3ee2-4e07-8bcc-61dbc5060e7b";

export const auLocale = "e89b9cc2-cee4-4829-9831-433406e0b792";

export const localeCodeIds = {
  en: ukLocale,
  en_AU: auLocale,
};

export const LocaleIdToCodeMap: { [key: string]: string } = {
  [ukLocale]: "en",
  [auLocale]: "en_AU",
};

export const localeIdToCountryCodeMap: { [key: string]: string } = {
  [ukLocale]: "+44",
  [auLocale]: "+61",
};

export const LocalePhoneNumberLength: { [key: string]: { min: number; max: number } } = {
  [ukLocale]: {
    min: 7,
    max: 10,
  },
  [auLocale]: {
    min: 5,
    max: 15,
  },
};

export const isAU = (localeId: string) => localeId === localeCodeIds["en_AU"];

export const AdminDomainLocaleCode: Record<string, "en" | "en_AU"> = {
  "backofficewisdom.healthassured.org": "en",
  "admin.wisdomwellbeing.com": "en_AU",
};

export const removeLocaleCountryCode = (phoneNumber: string, localeId: string) => {
  const regexPattern = new RegExp(`\\${localeIdToCountryCodeMap[localeId]}`, "g");
  const parsedPhoneNumber = phoneNumber?.replace(regexPattern, "");
  return parsedPhoneNumber;
};

export const parseLocalePhoneNumber = (phoneNumber: string, localeId: string) => {
  const parsedPhoneNumber = phoneNumber.replace(/^0+/, "");
  return `${localeIdToCountryCodeMap[localeId]}${parsedPhoneNumber}`;
};
