import { useState } from "react";
import { Chart } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import type { ChartOptions, ChartType, Plugin } from "chart.js";
import { useTranslation } from "react-i18next";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { ContextMenu } from "@brighthr/component-contextmenu";
import { ReactComponent as MoreCircle } from "shared/assets/Icons/more-circle.svg";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { ValueCallback } from "shared/core/types/callbacks";
import { UserTrackerValue } from "shared/request/myHealthyAdvantageApi";
import { ChartDayOffsets, generateChartData } from "./helpers";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";
import StylableButton from "shared/UI/Buttons/StylableButton";

ChartJS.register(...registerables);

export interface TrackerChartProps<TType extends ChartType = ChartType> {
  heading: string;
  yAxisStepSize: number;
  loading: boolean;
  showError: boolean;
  data: UserTrackerValue[];
  reload: VoidFunction;
  dateRange: { startDate: Date; endDate: Date };
  onChartViewOffsetChange: ValueCallback<ChartDayOffsets>;
  initialChartViewOffset: ChartDayOffsets;
  showYAxisTick?: boolean;
  yAxisMax?: number;
  yAxisLabel?: string;
  plugins?: Plugin<TType>[];
  menuItems?: React.ReactNode[];
}

export const TrackerChart = ({
  heading,
  data,
  dateRange,
  showError,
  reload,
  loading,
  onChartViewOffsetChange,
  initialChartViewOffset,
  showYAxisTick = true,
  yAxisStepSize,
  yAxisLabel,
  yAxisMax,
  plugins,
  menuItems,
}: TrackerChartProps) => {
  const { t } = useTranslation();

  const [view, setView] = useState(initialChartViewOffset);

  const setChartView = (newView: ChartDayOffsets) => {
    setView(newView);
    onChartViewOffsetChange(newView);
  };

  return (
    <RoundedCard>
      <h3 className="flex flex-row">
        <span className="flex-grow">{heading}</span>
        <Hideable hidden={!menuItems || showError || loading}>
          <ContextMenu
            menuPosition="left"
            contextName="actions"
            aria-label={t("trackers.actionsMenu")}
            customTriggerLayout={<MoreCircle height="20" width="20" />}
          >
            {menuItems}
          </ContextMenu>
        </Hideable>
      </h3>
      <ViewStateWrapper loading={loading} error={showError} errorMessage={t("healthHub.trackers.chart.error")} onRetry={reload}>
        <div className="h-82">
          <div className="h-72">
            <Chart
              type="bar"
              options={getChartOptions(yAxisStepSize, showYAxisTick, yAxisLabel, yAxisMax)}
              data={generateChartData(data, dateRange, view)}
              plugins={plugins}
            />
          </div>
          <div className="w-full flex flex-row justify-evenly items-center space-x-3 px-5 mt-2">
            <StylableButton
              className="w-full"
              text={t("healthHub.chartButtons.view7Days")}
              color="primary"
              fullWidth
              outlineButton={view !== ChartDayOffsets.week}
              onClick={() => setChartView(ChartDayOffsets.week)}
            />
            <StylableButton
              className="w-full"
              text={t("healthHub.chartButtons.view1Month")}
              color="primary"
              fullWidth
              outlineButton={view !== ChartDayOffsets.month}
              onClick={() => setChartView(ChartDayOffsets.month)}
            />
            <StylableButton
              className="w-full"
              text={t("healthHub.chartButtons.view1Year")}
              color="primary"
              fullWidth
              outlineButton={view !== ChartDayOffsets.year}
              onClick={() => setChartView(ChartDayOffsets.year)}
            />
          </div>
        </div>
      </ViewStateWrapper>
    </RoundedCard>
  );
};

function getChartOptions(yAxisStepSize: number, showYAxisTick: boolean, yAxisLabel?: string, yAxisMax?: number): ChartOptions {
  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        type: "linear" as const,
        display: true,
        min: 0,
        max: yAxisMax,
        suggestedMax: yAxisStepSize,
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: yAxisLabel,
          padding: yAxisLabel ? 0 : 8,
        },
        ticks: {
          stepSize: yAxisStepSize,
          callback: function (label) {
            if (showYAxisTick) {
              const parsedLabel = parseInt(label.toString());
              return new Intl.NumberFormat().format(parsedLabel);
            }
            return "";
          },
        },
      },
      x: {
        display: true,
        grid: {
          drawOnChartArea: true,
        },
        ticks: {
          maxRotation: 57.899,
          minRotation: 57.899,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
}
