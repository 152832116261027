import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { t } from "i18next";
import { useState } from "react";
import { IntegerInput } from "shared/UI/Inputs/IntegerInput/IntegerInput";
import { ConfirmationModal } from "shared/UI/Modal/ConfirmationModal/ConfirmationModal";

export const StepsInputModal = ({ onClose }: { onClose: VoidFunction }) => {
  const { getCurrentValue, setCurrentValue } = useTrackerContext();
  const [stepCount, setStepCount] = useState<number | undefined>(undefined);

  const onSave = () => {
    setCurrentValue(TrackerType.Steps, stepCount!);
    onClose();
  };

  return (
    <ConfirmationModal
      title={t("healthHub.trackers.steps.addData.heading")}
      cancelButtonText={t("healthHub.trackers.steps.addData.cancel")}
      confirmButtonText={t("healthHub.trackers.steps.addData.save")}
      headerColour="inverted"
      confirmButtonDisabled={!stepCount || stepCount < 0}
      onCancel={onClose}
      onConfirm={onSave}
    >
      <div>
        <IntegerInput
          aria-label={t("healthHub.trackers.steps.addData.inputLabel")}
          defaultValue={String(getCurrentValue(TrackerType.Steps))}
          onChange={(e) => setStepCount(parseInt(e.target.value))}
          min={1}
          maxLength={5}
          suffix={t("healthHub.trackers.steps.addData.inputSuffix")}
        />
      </div>
    </ConfirmationModal>
  );
};
