import { UserInterestPreference } from "shared/request/myHealthyAdvantageApi";
import { appInsights } from "./AppInsights";

interface filterData {
  interestPreferences?: UserInterestPreference[];
  selectedInterests?: string[];
  filterValue?: string;
}

export const trackFilteredInterests = (data?: filterData) => {
  if (data?.filterValue) {
    appInsights.trackEvent({ name: `Filter-preference-${data?.filterValue}` });
    return;
  } else {
    const categories = data?.selectedInterests?.map(
      (_category) => data?.interestPreferences?.find((preference) => preference.categoryId === _category)?.name
    );
    categories?.map((category) => appInsights.trackEvent({ name: `Filter-preference-${category}` }));
  }
};

export const trackAppEvent = (name: string) => {
  appInsights.trackEvent({ name: name });
};

export const trackFormSubmission = (name: string, values: Record<string, any>) => {
  appInsights.trackEvent({ name: `Form-Submission-${name}` }, { values: values });
};

export const trackMoodTracker = (mood: string) => {
  appInsights.trackEvent({ name: "Wisdom-Mood-Tracker" }, { mood });
};
