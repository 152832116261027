import {
  FourWeekPlanActivity,
  ProgressEntry,
  useFinishFourWeekPlanFunctionRequest,
  useGetUserFourWeekPlanProgressFunctionRequest,
  useStartFourWeekPlanFunctionRequest,
  useSubmitFourWeekPlanActivitiesFunctionRequest,
} from "shared/request/myHealthyAdvantageApi";

import { DailyProgressActionButton } from "./DailyProgressActionButton";
import { DayNumber } from "./DayNumber";
import { Hideable } from "shared/UI/Hideable/Hideable";
import ReflectModal from "./modals/ReflectModal";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { StartedOnDate } from "./StartedOnDate";
import { SubmitActivitiesModal } from "./modals/SubmitActivitiesModal";
import { TipsAndAdviceModal } from "./modals/TipsAndAdviceModal";
import { TrackerProgress } from "UIPalette/TrackerProgress/TrackerProgress";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { trackFormSubmission } from "core/monitoring/Events";

export type DailyProgressProps = {
  fourWeekPlanId: string;
};
export const DailyProgress = ({ fourWeekPlanId }: DailyProgressProps) => {
  const { t } = useTranslation();
  const {
    data: progressData,
    isLoading,
    error,
    mutate: refreshDailyProgress,
  } = useGetUserFourWeekPlanProgressFunctionRequest({ id: fourWeekPlanId });
  const { trigger: startFourWeekPlanAPI } = useStartFourWeekPlanFunctionRequest({});
  const { trigger: submitActivitiesAPI } = useSubmitFourWeekPlanActivitiesFunctionRequest({});
  const { trigger: finishFourWeekPlanAPI } = useFinishFourWeekPlanFunctionRequest({});
  const [showTips, setShowTips] = useState(false);
  const [showSubmitActivities, setShowSubmitActivities] = useState(false);
  const [showReflectModal, setShowReflectModal] = useState(false);

  const { successNotes, challengeNotes } = progressData ?? {};

  const progressIndicators = [] as Array<{ day: number; percentForDay: number }>;
  for (let day = 1; day < 29; day++) {
    const percentForDay = progressData?.entries?.find((x: ProgressEntry) => x.day === day)?.percentageComplete ?? 0;
    progressIndicators.push({ day, percentForDay });
  }

  async function tipsOnConfirm() {
    try {
      await startFourWeekPlanAPI({ body: { fourWeekPlanDatoId: fourWeekPlanId } });
      setShowTips(false);
      setShowSubmitActivities(true);
    } catch {
      toastNotify(t("fourWeekPlans.dailyProgress.startPlanError"), "error");
    }
  }

  async function onSubmitActivities(activities: FourWeekPlanActivity[]) {
    const data = {
      activities: activities,
      datoFourWeekPlanId: fourWeekPlanId,
    };
    trackFormSubmission("Four-Week-Plan-Activity", data);
    try {
      await submitActivitiesAPI({
        body: data,
      });
      await refreshDailyProgress();
      setShowSubmitActivities(false);
    } catch {
      toastNotify(t("fourWeekPlans.dailyProgress.submitError"), "error");
    }
  }

  async function onFinishPlan(successNotes: string, challengeNotes: string) {
    const { userFourWeekPlanId } = progressData!;
    if (!userFourWeekPlanId) {
      return;
    }

    try {
      await finishFourWeekPlanAPI({
        body: {
          userFourWeekPlanId,
          successNotes,
          challengeNotes,
        },
      });
      setShowReflectModal(false);
      await refreshDailyProgress();
    } catch {
      toastNotify(t("fourWeekPlans.dailyProgress.finishError"), "error");
    }
  }

  return (
    <>
      <div className="mt-2 mb-2">
        <RoundedCard>
          <h3>{t("fourWeekPlans.dailyProgress.title")}</h3>
          <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={t("fourWeekPlans.dailyProgress.error")} onRetry={refreshDailyProgress}>
            <StartedOnDate startDate={progressData?.startDate} />
            <div className="flex flex-wrap">
              {progressIndicators.map(({ day, percentForDay }) => (
                <div key={day} className="sm:basis-1/7 basis-1/4">
                  <TrackerProgress percentage={percentForDay}>
                    <DayNumber day={day} />
                  </TrackerProgress>
                </div>
              ))}
            </div>
            <div className="mt-8">
              <DailyProgressActionButton
                startDate={progressData?.startDate}
                planOnClicks={{
                  start: () => setShowTips(true),
                  mid: () => setShowSubmitActivities(true),
                  end: () => setShowReflectModal(true),
                }}
                hasNotes={!!successNotes ?? !!challengeNotes}
              />
            </div>
          </ViewStateWrapper>
        </RoundedCard>

        <Hideable hidden={!showTips}>
          <TipsAndAdviceModal datoFourWeekPlanId={fourWeekPlanId} onCancel={() => setShowTips(false)} onConfirm={tipsOnConfirm} />
        </Hideable>

        <Hideable hidden={!showSubmitActivities}>
          <SubmitActivitiesModal
            datoFourWeekPlanId={fourWeekPlanId}
            onCancel={() => {
              setShowSubmitActivities(false);
            }}
            onConfirm={onSubmitActivities}
            startDate={progressData?.startDate}
          />
        </Hideable>

        <Hideable hidden={!showReflectModal}>
          <ReflectModal
            onCancel={() => setShowReflectModal(false)}
            onConfirm={onFinishPlan}
            notes={{
              successNotes,
              challengeNotes,
            }}
          />
        </Hideable>
      </div>
    </>
  );
};
