import { useTranslation } from "react-i18next";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { useState } from "react";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { AddWaterIntakeModal } from "../AddWaterIntakeModal/AddWaterIntakeModal";
import { TrackerType } from "core/state/Trackers/TrackerType";

export const AddWaterIntakeCard = () => {
  const { getCurrentValue, setCurrentValue: setProgress } = useTrackerContext();
  const [showAddData, setShowAddData] = useState(false);
  const { t } = useTranslation();

  function save(millilitres: number) {
    setProgress(TrackerType.Water, millilitres);
    setShowAddData(false);
  }

  return (
    <>
      <StylableButton text={t("healthHub.trackers.buttons.addData")} color="primary" fullWidth={true} onClick={() => setShowAddData(true)} />
      <Hideable hidden={!showAddData}>
        <AddWaterIntakeModal
          millilitres={getCurrentValue(TrackerType.Water)}
          onClose={() => {
            setShowAddData(false);
          }}
          onSave={save}
        />
      </Hideable>
    </>
  );
};
